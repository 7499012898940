import * as React from 'react';
import {graphql} from 'gatsby';
import {styled} from 'twin.macro';
import {useI18next} from 'gatsby-plugin-react-i18next';

import Layout from '../components/Layout';
import {Container, Section} from '../components/Grid';
import ArticleItem from '../components/blog/ArticleItem';
import PageHero from '../components/shared/hero';

const Heading = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 40px;
  line-height: 127%;
  color: #224b45;
`;

const FlexRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const BlogIndex = ({data, location}) => {
  const {language, t} = useI18next();
  const posts = data.allMdx.nodes;
  const {background, backgroundMobile, backgroundTablet} = data;
  const filteredPosts = posts.filter(post => {
    const postLang = post.frontmatter.language || 'en';
    return postLang === language && post.frontmatter.type === 'blog';
  });

  return (
    <Layout>
      <PageHero
        content={{
          heading: t('blog_title'),
          text: t('blog_subline'),
        }}
        imgPosition={'95% 0%'}
        imgMobilePosition={'0% 100%'}
        imgObj={{
          background,
          backgroundMobile,
          backgroundTablet,
        }}
      />
      <Container>
        <Section>
          <Heading>
            {t('blog_articles_title')}: {filteredPosts.length}
          </Heading>
          <FlexRow>
            {filteredPosts.map((post) => {
              const title = post.frontmatter.title || post.frontmatter.slug;
              return <ArticleItem key={title} post={post} />;
            })}
          </FlexRow>
        </Section>
      </Container>
    </Layout>
  );
};

export default BlogIndex;

export const Head = () => <></>;

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    background: file(relativePath: {eq: "blog-hero.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(relativePath: {eq: "blog-hero-mobile.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundTablet: file(relativePath: {eq: "blog-hero-tablet.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    allMdx(sort: {fields: [frontmatter___date], order: ASC}) {
      nodes {
        excerpt
        frontmatter {
          date
          title
          description
          slug
          language
          tags
          type
          coverImg {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    locales: allLocale(
      filter: {language: {eq: $language}, ns: {in: ["common", "not_found"]}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
